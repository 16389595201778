import { adminConstants } from '../../../../../../difference-admin/app/shared/constants/constants';
import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { dataTableEvents } from '../../../../../../difference-admin/app/shared/constants/data-table-events';
import { Router } from '@angular/router';
import { processCommonMenuItems } from 'projects/difference-admin/app/shared/services/table-menu-process.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Criterion, SearchItemModel } from 'projects/difference/app/shared/components/data-table/data-table-search/data-table-search.component';
import { SubscriptionHandler } from 'projects/difference/app/shared/components/subscriptionHandler';
import { FilterRequestItem } from 'projects/difference/webapi/Difference.WebApi';
import { ClientSideSearchService } from 'projects/difference/app/shared/components/data-table/services/client-side-search.service';
import { Pagination } from 'projects/difference-admin/app/shared/services/base-event-service';
import { constants } from '../../../constants/constants';

@Component({
  selector: 'app-client-side-data-table',
  templateUrl: './client-side-data-table.component.html'
})
export class ClientSideDataTableComponent implements AfterViewInit, OnInit, OnDestroy, OnChanges {
  @Input() id: string;
  @Input() columns: DataTables.ColumnSettings[];
  @Input() sort: any;
  @Input() eventService: any;
  @Input() data: any;
  @Input() paging: any = true;
  @Input() ordering: any = true;

  @ViewChild(DataTableDirective, { static: false })
  dtOptions: DataTables.Settings;

  dtTrigger = new Subject();

  selectedIds: string[] = [];
  stateDuration = -1; // alternative variant of state data save
  subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();
  filterItems: SearchItemModel[];

  listenerFn: () => void;

  constructor(private router: Router, private renderer: Renderer2, private clientSideSearchService: ClientSideSearchService) {}

  ngOnInit(): void {
    localStorage.removeItem(`DataTables_${this.id}_/devis-admin`);
    this.initSubscriptions();
    this.dtOptions = {
      ...this.getDTOptions(),
      // stateDuration: this.stateDuration,
      stateLoaded: (settings: any, state: any) => {},
      ajax: (dataTablesParameters: any, callback) => {
        this.ajaxFunction(dataTablesParameters, callback);
      }
    };
  }

  ngOnDestroy() {
    if (this.listenerFn) {
      this.listenerFn();
    }
    this.dtTrigger.unsubscribe();
    this.eventService.dataWasLoaded = false;
    this.subscriptionHandler.unsubscribeAll();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // todo do object comparison correct (for ex. use Lodash or create our own)
    if (changes?.data && changes?.data.currentValue !== undefined && JSON.stringify(changes.data.currentValue) !== JSON.stringify(changes.data.previousValue)) {
      const datatable = $(`#${this.id}`).DataTable();
      datatable.destroy();
      this.rerender();
    }
  }

  rerender(data?: any): void {
    $(`#${this.id}`).DataTable({
      ...this.getDTOptions(),
      // stateDuration: this.stateDuration,
      ajax: (dataTablesParameters: any, callback) => {
        this.ajaxFunction(dataTablesParameters, callback, data);
      }
    });
  }

  ajaxFunction(dataTablesParameters: any, callback: any, data?: any): any {
    this.eventService.dataWasLoaded = true;
    callback({
      data: data || this.data
    });
  }

  getDTOptions(): any {
    return {
      destroy: true,
      paging: this.paging,
      pagingType: 'full_numbers',
      pageLength: this.getPageLength(),
      ordering: this.ordering,
      order: this.sort,
      serverSide: false,
      processing: false,
      stateSave: true,
      searching: false,
      columns: this.columns,
      language: adminConstants.dataTableLanguageSettings,
      stateSaveParams: (data: any) => {
        // FunctionStateSaveParams
        const pagination = {
          start: data.oSavedState.start,
          length: data.oSavedState.length
        };

        this.eventService?.savePagination(pagination);
      }
    };
  }

  getPageLength(): number {
    const preSavedPagination: Pagination = this.eventService?.getPagination();

    if (preSavedPagination?.length) {
      return preSavedPagination.length;
    }

    return constants.defaultDataTablePageLength;
  }

  ngAfterViewInit(): void {
    this.listenerFn = this.renderer.listen('document', 'click', event => {
      this.processThreeDotsMenu(event);
      this.processSelectors(event);

      processCommonMenuItems(this.eventService, this.router, event);

      event.stopPropagation();
    });
    this.dtTrigger.next();
  }

  resetSelected(): void {
    $('input#selectItem').prop('checked', false);
    $('input#selectAll').prop('checked', false);
    this.selectedIds = [];
  }

  processThreeDotsMenu(event: any) {
    const menus = document.getElementsByClassName('dropdown-content');

    for (let i = 0; i < menus.length; i++) {
      menus[i].classList.remove('show');
    }

    if (event.target.id === 'threeDots') {
      if (event.target.nextSibling.nextSibling.classList.contains('show')) {
        event.target.nextSibling.nextSibling.classList.remove('show');
      } else {
        event.target.nextSibling.nextSibling.classList.toggle('show');
      }
    }
  }

  processSelectors(event: any) {
    if (event.target.id === 'selectAll') {
      $('input#selectItem').prop('checked', event.target.checked);
      this.selectedIds = [];
      if (event.target.checked) {
        this.selectedIds = $('input#selectItem')
          .map(function () {
            return this.getAttribute(dataTableEvents.common.checkedId);
          })
          .get();
      }
    }
    if (event.target.id === 'selectItem') {
      const itemId = event.target.getAttribute(dataTableEvents.common.checkedId);

      if (event.target.checked) {
        this.selectedIds.push(itemId);
      } else {
        this.selectedIds = this.selectedIds.filter((id: string) => {
          return id !== itemId;
        });
      }
    }

    this.eventService.onSelected(this.selectedIds);
  }

  clearSelectedItems(): void {
    this.selectedIds = [];
    $('input#selectAll').prop('checked', false);
  }

  private initSubscriptions(): void {
    this.subscriptionHandler.subscriptions = this.eventService.getFilterConditionsSubject().subscribe((filterItems: SearchItemModel[]) => {
      this.filterItems = filterItems.map((item: SearchItemModel) => {
        item.searchValue = item.searchValue?.toString();
        return item;
      });
      this.processFilters();
    });
  }

  private processFilters(): void {
    if (this.filterItems?.length > 0) {
      this.filterItems.forEach(filterItem => {
        const criterion = this.eventService?.getFilterCriterions().find((filterCriterion: Criterion) => filterItem.criterion === filterCriterion.id);

        this.rerender(
          this.clientSideSearchService.doFilterItems(
            {
              propertyNames: criterion.data,
              propertyValue: filterItem.searchValue,
              operator: filterItem.operator
            } as FilterRequestItem,
            this.data,
            criterion.type
          )
        );
      });
    } else {
      this.rerender();
    }
  }
}
