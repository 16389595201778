import { constants } from 'projects/difference/app/shared/constants/constants';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { ControlCenter, DataFieldConfig } from 'projects/difference/webapi/Difference.WebApi';
import { formFields } from 'projects/difference/app/shared/constants/form-fields';
import { LookupDataService } from 'projects/difference/app/services/lookup-data.service';
import { RadioGroupFormControl } from '../../../controls/radio-group-form-control/radio-group-form-control';
import { collections } from 'projects/difference/app/shared/constants/collections';
import { DateFormControl } from '../../../controls/date-form-control/date-form-control';
import { NameWithDigitsFormControl } from '../../../controls/name-with-digits-form-control/name-with-digits-form-control';
import { NumberFormControl } from '../../../controls/number-form-control/number-form-control';
import { FormValuesChangedModel } from '../../abstract-form';
import { generateUtcFrom } from 'projects/difference/app/shared/utils/date-time.utils';

function getControlCenterFormComponentControl(config: DataFieldConfig, mode: FormMode, lookupDataService: LookupDataService): any {
  const isReadOnly = mode === FormMode.View ? true : config.isReadOnly;

  switch (config.name) {
    case formFields.controlCenterForm.isMemberManagementCenter: {
      return new RadioGroupFormControl(config.isRequired, isReadOnly, {
        collection: collections.yesNoBoolean,
        valuePropertyName: constants.valuePropertyName,
        keyPropertyName: constants.namePropertyName
      });
    }
    case formFields.controlCenterForm.accessionDate: {
      return new DateFormControl(config.isRequired, isReadOnly);
    }
    case formFields.controlCenterForm.managementCenterName: {
      return new NameWithDigitsFormControl(config.isRequired, isReadOnly);
    }
    case formFields.controlCenterForm.managementCenterAddress: {
      return new NameWithDigitsFormControl(config.isRequired, isReadOnly);
    }
    case formFields.controlCenterForm.managementCenterNumber: {
      return new NumberFormControl(config.isRequired, isReadOnly);
    }
    case formFields.controlCenterForm.isToDoMemberManagementCenter: {
      return new RadioGroupFormControl(config.isRequired, isReadOnly, {
        collection: collections.yesNoBoolean,
        valuePropertyName: constants.valuePropertyName,
        keyPropertyName: constants.namePropertyName
      });
    }
    default: {
      return new NameWithDigitsFormControl(config.isRequired, isReadOnly);
    }
  }
}

function controlCenterFormToViewModel(config: DataFieldConfig[], data: any, lookupDataService: LookupDataService): any[] {
  const viewModel: any = {};

  if (!data) {
    return viewModel;
  }

  config?.forEach((configItem: any) => {
    const propertyName = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);
    viewModel[configItem.name] = data[propertyName];
  });

  return viewModel;
}

function controlCenterViewModelToDataModel(config: DataFieldConfig[], data: FormValuesChangedModel): ControlCenter {
  const objModel: any = {};
  const model = data?.model;

  if (data) {
    config?.forEach((configItem: any) => {
      const propertyNameForSaveObj = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);

      if (configItem.name === formFields.controlCenterForm.accessionDate) {
        objModel[propertyNameForSaveObj] = model[configItem.name] ? generateUtcFrom(new Date(model[configItem.name])) : null;
      } else {
        objModel[propertyNameForSaveObj] = model[configItem.name];
      }
    });

    objModel.taxControlCenterId = data.formId;
    objModel.profileGuid = data.profileGuid;
  }

  return new ControlCenter(objModel);
}

export { getControlCenterFormComponentControl, controlCenterFormToViewModel, controlCenterViewModelToDataModel };
