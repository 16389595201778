export const formFields = {
  contactMainForm: {
    isCompany: 'IsCompany', // name from config
    jurTypeId: 'JurTypeId',
    civilityId: 'CivilityId',
    firstName: 'FirstName',
    lastName: 'LastName',
    commercialName: 'CommercialName',
    sirenNumber: 'SirenNumber',
    addressLine: 'AddressLine',
    zipCode: 'ZipCode',
    city: 'City',
    office: 'Office',
    sourceInfoAboutUs: 'SourceInfoAboutUs',
    sourceInfoAboutUsOther: 'SourceInfoAboutUsOther',
    partnerId: 'PartnerId'
  },
  introductionForm: {
    nafCode: 'NafCode',
    activityStartDate: 'ActivityStartDate',
    activityDescription: 'ActivityDescription',
    activityAreaId: 'ActivityAreaId',
    activityAreaDescription: 'ActivityAreaDescription',
    turnover: 'Turnover',
    employeesNumber: 'EmployeesNumber',

    individualsPercentage: 'IndividualsPercentage',
    othersPercentage: 'OthersPercentage',
    professionalsPercentage: 'ProfessionalsPercentage',
    communitiesPercentage: 'CommunitiesPercentage',

    missions: 'Missions', // only on the client side
    customizedMissions: 'CustomizedMissions',
    otherMissionRequestDescription: 'OtherMissionRequestDescription',
    missionsStartDate: 'MissionsStartDate',
    isCustomMissionsStartDate: 'IsCustomMissionsStartDate',

    details: 'Details',
    availability: 'Availability'
  },
  beneficiariesForm: {
    isDirect: 'IsDirect',
    address: 'Address',
    firstName: 'FirstName',
    lastName: 'LastName',
    sharesPercentage: 'SharesPercentage'
  },
  contactSecondFormComponent: {
    contactRole: 'ContactRoleId',
    civility: 'CivilityId',
    firstName: 'FirstName',
    lastName: 'LastName',
    phone: 'Phones',
    email: 'Email'
  },
  additionalInfo: {
    isAccountantEverHired: 'IsAccountantEverHired',
    isCommitmentEndDateKnown: 'IsCommitmentEndDateKnown',
    commitmentEndDate: 'CommitmentEndDate',
    currentAccountantCompanyName: 'CurrentAccountantCompanyName',
    currentAccountantName: 'CurrentAccountantName',
    corporateAccountsClosingDate: 'CorporateAccountsClosingDate',
    currentOrganizationDescription: 'CurrentOrganizationDescription'
  },
  stakeholdersForm: {
    address: 'Address',
    email: 'Email',
    firstName: 'FirstName',
    lastName: 'LastName',
    phone: 'Phone',
    nature: 'Nature',
    stakeholderTypeId: 'StakeholderTypeId'
  },
  ventesPrestationForm: {
    isSalesManagementTool: 'isSalesManagementTool',
    isWantSalesManagementTool: 'isWantSalesManagementTool',
    tools: 'tools',
    otherTool: 'otherToolName'
  },
  achatsForm: {
    isSalesManagementTool: 'isSalesManagementTool',
    isWantSalesManagementTool: 'isWantSalesManagementTool',
    tools: 'tools',
    otherTool: 'otherToolName'
  },
  notesDeFraisForm: {
    isSalesManagementTool: 'isSalesManagementTool',
    isWantSalesManagementTool: 'isWantSalesManagementTool',
    tools: 'tools',
    otherTool: 'otherToolName'
  },
  autresFluxForm: {
    isSalesManagementTool: 'isSalesManagementTool',
    isWantSalesManagementTool: 'isWantSalesManagementTool',
    toolName: 'toolName'
  },
  salesForm: {
    isWithoutInvoices: 'IsWithoutInvoices',
    isCashSelling: 'IsCashSelling',
    periodId: 'PeriodId',
    numberPerPeriod: 'NumberPerPeriod',
    formatId: 'FormatId',
    managementTools: 'ManagementTools',
    hasTool: 'HasTool',
    isInterested: 'IsInterested'
  },
  purchasesForm: {
    periodId: 'PeriodId',
    numberPerPeriod: 'NumberPerPeriod',
    formatId: 'FormatId',
    hasTool: 'HasTool',
    isInterested: 'IsInterested',
    managementTools: 'ManagementTools'
  },
  expensesForm: {
    periodId: 'PeriodId',
    numberPerPeriod: 'NumberPerPeriod',
    formatId: 'FormatId',
    hasTool: 'HasTool',
    isInterested: 'IsInterested',
    managementTools: 'ManagementTools'
  },
  otherStreamsForm: {
    natureFlex: 'NatureFlex',
    periodId: 'PeriodId',
    numberPerPeriod: 'NumberPerPeriod',
    formatId: 'FormatId',
    hasManagementTool: 'HasManagementTool',
    IsIntrestedInManagementTool: 'IsIntrestedInManagementTool',
    toolName: 'ToolName',
    comments: 'Comments'
  },
  otherToolsForm: {
    hasManagementMoneyTool: 'HasManagementMoneyTool',
    isIntrestedInManagementMoneyTool: 'IsIntrestedInManagementMoneyTool',
    managementMoneyTools: 'ManagementMoneyTools',

    hasManagementStocksTool: 'HasManagementStocksTool',
    isIntrestedInManagementStocksTool: 'IsIntrestedInManagementStocksTool',
    managementStocksTools: 'ManagementStocksTools',

    hasElectronicInvoicingTool: 'HasElectronicInvoicingTool',
    isIntrestedInElectronicInvoicingTool: 'IsIntrestedInElectronicInvoicingTool',
    electronicInvoicingTools: 'ElectronicInvoicingTools',

    otherUtils: 'OtherUtils'
  },
  banksForm: {
    bankId: 'BankId',
    name: 'Name'
  },
  mainTaxesForm: {
    natureId: 'NatureId',
    methodId: 'MethodId',
    currentTaxRegimeId: 'CurrentTaxRegimeId',
    taxationModeId: 'TaxationModeId',
    tvaNumber: 'TvaNumber',
    responsibleForVatid: 'ResponsibleForVatid'
  },
  debDesForm: {
    isDealOutsideEurope: 'IsDealOutsideEurope',
    isDeb: 'IsDeb',
    isDes: 'IsDes'
  },
  controlCenterForm: {
    isMemberManagementCenter: 'IsMemberManagementCenter',
    accessionDate: 'AccessionDate',
    managementCenterName: 'ManagementCenterName',
    managementCenterAddress: 'ManagementCenterAddress',
    managementCenterNumber: 'ManagementCenterNumber',
    isToDoMemberManagementCenter: 'IsToDoMemberManagementCenter'
  },
  featuresTaxationForm: {
    features: 'Features'
  },
  accountingForm: {
    accountingEntryId: 'AccountingEntryId',
    frequencyId: 'FrequencyId',
    isIntermediateSituationalPoints: 'IsIntermediateSituationalPoints',
    howFrequencyId: 'HowFrequencyId',
    comment: 'Comment',

    hasAccountingSoftware: 'HasAccountingSoftware',
    isIntrestedInAccountingSoftware: 'IsIntrestedInAccountingSoftware',
    accountingSoftware: 'AccountingSoftware',

    hasBusinessManagementSoftware: 'HasBusinessManagementSoftware',
    isIntrestedInBusinessManagementSoftware: 'IsIntrestedInBusinessManagementSoftware',
    businessManagementSoftware: 'BusinessManagementSoftware',

    isToDoMemberManagementCenter: 'IsToDoMemberManagementCenter',
    useTools: 'UseTools',
    credentialTransferMethods: 'CredentialTransferMethods',
    accountingClassificationMode: 'AccountingClassificationMode',
    archivingAccounts: 'ArchivingAccounts',
    formats: 'Formats',
    transmissionFrequencyId: 'TransmissionFrequencyId'
  },
  companyAgreement: {
    isCompanyAgreement: 'IsCompanyAgreement',
    conventionTeam: 'ConventionTeam',
    details: 'Details'
  },
  social: {
    hasDigitalSafeTool: 'HasDigitalSafeTool',
    digitalSafeTool: 'DigitalSafeTool',
    isIntrestedInDigitalSafeTool: 'IsIntrestedInDigitalSafeTool',
    wageManagementTool: 'WageManagementTool',
    hasPersonnelInformationSystemTool: 'HasPersonnelInformationSystemTool',
    personnelInformationSystemTool: 'PersonnelInformationSystemTool',
    isIntrestedInPersonnelInformationSystemTool: 'IsIntrestedInPersonnelInformationSystemTool'
  },
  responsibleManagement: {
    responsibleManagementId: 'ResponsibleManagementId',
    serviceProviderName: 'ServiceProviderName',
    socialFeatures: 'SocialFeatures'
  }
};
