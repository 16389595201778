import moment from 'moment';

export function generateUtcFrom(date: any, withTime?: boolean): Date {
  const dateToProcess = isMomentObject(date) ? date.toDate() : date;

  const dateToReturn = new Date(
    Date.UTC(
      dateToProcess.getFullYear(),
      dateToProcess.getMonth(),
      withTime ? dateToProcess.getUTCDate() : dateToProcess.getDate(),
      withTime ? dateToProcess.getUTCHours() : 0,
      withTime ? dateToProcess.getUTCMinutes() : 0,
      withTime ? dateToProcess.getUTCSeconds() : 0,
      0
    )
  );

  return dateToReturn;
}

function isMomentObject(date: any) {
  return date instanceof moment;
}
