import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, debounceTime, mergeMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { ApplicationPaths, QueryParameterNames } from './api-authorization.constants';
import { Router } from '@angular/router';
import { JeSignTokensDataService } from 'projects/difference/app/services/je-sign-tokens-data.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public noTokenUrls = ['suggestions.pappers.fr'];
  public withJeSignTokensUrls = ['sign/status', 'create-sign-collection'];

  constructor(private router: Router, private authService: AuthService, private jeSignTokensDataService: JeSignTokensDataService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.authService.getAccessToken().pipe(mergeMap(token => this.processRequestWithToken(token, request, next)));
  }

  // Checks if there is an access_token available in the authorize service
  // and adds it to the request in case it's targeted at the same origin as the
  // single page application.
  private processRequestWithToken(token: string | null, req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!!token) {
      let needAuthHeader = true;
      let needJeSignHeader = false;

      this.noTokenUrls.forEach((url: string) => {
        if (req.url.indexOf(url) !== -1) {
          needAuthHeader = false;
        }
      });

      this.withJeSignTokensUrls.forEach((url: string) => {
        if (req.url.indexOf(url) !== -1) {
          needJeSignHeader = true;
        }
      });

      if (needAuthHeader) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
      }

      if (needJeSignHeader && this.jeSignTokensDataService.isTokensValid()) {
        req = req.clone({
          setHeaders: {
            JeSignExpertOfficeToken: this.jeSignTokensDataService.getTokens().office?.token,
            JeSignExpertUserToken: this.jeSignTokensDataService.getTokens().user?.token
          }
        });
      }
    }

    return next.handle(req).pipe(
      catchError(error => {
        if (this.router.url.indexOf(ApplicationPaths.Login) === -1 && this.router.url.indexOf(ApplicationPaths.LogOutCallback) === -1) {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            this.router.navigate([ApplicationPaths.Login], {
              queryParams: {
                [QueryParameterNames.ReturnUrl]: req.url
              }
            });
          }
        }
        return throwError(error);
      })
    );
  }

  private isSameOriginUrl(req: any): boolean {
    // It's an absolute url with the same origin.
    if (req.url.startsWith(`${window.location.origin}/`)) {
      return true;
    }

    // It's a protocol relative url with the same origin.
    // For example: //www.example.com/api/Products
    if (req.url.startsWith(`//${window.location.host}/`)) {
      return true;
    }

    // It's a relative url like /api/Products
    if (/^\/[^\/].*/.test(req.url)) {
      return true;
    }

    // It's an absolute or protocol relative url that
    // doesn't have the same origin.
    return false;
  }
}
