import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FileUploadControl, FileUploadValidators, ValidationErrors } from '@iplab/ngx-file-upload';
import { constants } from '../../../constants/constants';
import { formatBytes } from '../../../extentions/extensions';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit, OnChanges {
  @Input() files: File[];
  @Input() hideAfterUpload?: boolean;
  @Input() multiple?: boolean = false;
  @Input() title?: string = 'Ajouter un fichier';
  @Input() acceptExtension?: string[] = constants.fileUploaderSupportedTypes;
  @Input() hideChosenFiles?: boolean;
  @Output() onChange: EventEmitter<File[]> = new EventEmitter();

  public fileUploadControl: FileUploadControl;
  private prevOneFileName: string;

  constructor() {}

  ngOnInit(): void {
    this.fileUploadControl = new FileUploadControl(
      {
        listVisible: false,
        accept: this.acceptExtension
      },
      FileUploadValidators.fileSize(constants.fileUploaderMaxFileSize)
    );

    if (this.files) {
      this.fileUploadControl?.clear();

      this.files.forEach((file: File) => {
        this.fileUploadControl?.addFile(file);
      });
    }

    this.fileUploadControl.valueChanges.subscribe((values: File[]) => {
      if (!!this.prevOneFileName && values?.length === 1 && values[0].name !== this.prevOneFileName) {
        this.prevOneFileName = values[0].name;
        this.onChange.next(values);
        return;
      }

      if (this.files?.length === 1) {
        this.prevOneFileName = this.files[0].name;
      }

      if (values?.length !== this.files?.length && values?.length > 0) {
        this.onChange.next(values);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.files && changes?.files.currentValue !== undefined) {
      this.files = changes.files.currentValue;
      this.fileUploadControl?.clear();

      this.files.forEach((file: File) => {
        this.fileUploadControl?.addFile(file);
      });
    }
  }

  removeFile(file: any): void {
    if (confirm('Etes vous sûr de vouloir supprimer ce document ?')) {
      this.fileUploadControl.removeFile(file);
    }
  }

  get isHideUploader(): boolean {
    if (this.hideAfterUpload) {
      return this.fileUploadControl.size > 0;
    } else {
      return false;
    }
  }

  get errors(): Array<ValidationErrors> {
    const errorsList = this.fileUploadControl.getError();

    if (errorsList?.length > 0) {
      return errorsList;
    } else {
      return null;
    }
  }

  get errorMessages(): string[] {
    let messages: string[];

    if (this.errors) {
      this.errors.forEach((error: ValidationErrors) => {
        const errorsFileSize = error['fileSize'];
        if (errorsFileSize) {
          errorsFileSize.forEach((fsError: any) => {
            const text = `Le fichier est trop volumineux (${formatBytes(fsError.actual)}). Taille maximale autorisée : ${formatBytes(fsError.maxSize)}.`;
            if (!messages) {
              messages = [text];
            } else {
              messages.push(text);
            }
          });
        }
      });
      return messages;
    } else {
      return null;
    }
  }
}
