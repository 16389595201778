import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { ProspectsAutocompleteFormControl } from './prospects-autocomplete-form-control';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import '../../../extentions/string.extensions';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { constants } from '../../../constants/constants';

@Component({
  selector: 'app-prospects-autocomplete-form-control',
  templateUrl: './prospects-autocomplete-form-control.component.html'
})
export class ProspectsAutocompleteFormControlComponent extends AbstractFormControlComponent<ProspectsAutocompleteFormControl> implements OnInit {
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) inputAutoComplete: MatAutocompleteTrigger;

  @Output() onSelected: EventEmitter<any> = new EventEmitter();
  @Input() parentForm: FormGroup;
  @Input() title: string;
  @Input() controlName: string;
  @Input() placeholder: string;
  @Input() autocompleteOptions: any[] = [];
  @Input() useDisplayWith: boolean;
  @Input() showAllAfterInit: boolean;
  @Input() inline: boolean;

  faCheck: any = faCheck;

  filteredOptions: Observable<any[]>;
  autocompleteControl: ProspectsAutocompleteFormControl;

  ngOnInit(): void {
    this.autocompleteControl = this.parentForm.get(this.controlName) as ProspectsAutocompleteFormControl;

    if (this.showAllAfterInit) {
      setTimeout(() => {
        this.autocompleteControl.patchValue('');
        setTimeout(() => {
          this.inputAutoComplete.openPanel();
          this.autocompleteControl.updateValueAndValidity();
        }, 1);
      });
    }

    this.filteredOptions = this.parentForm.get(this.controlName).valueChanges.pipe(
      startWith(''),
      map((state: any) => {
        if (this.autocompleteControl.untouched && this.showAllAfterInit) {
          setTimeout(() => {
            this.autocompleteControl.markAsTouched();
          });
          return [];
        }
        return this._filterOptions(state);
      })
    );
  }

  itemSelected(event: any): void {
    this.parentForm.get(this.controlName).patchValue(event.option.value);
    this.onSelected.emit(this.control.value);
  }

  public get isInline(): boolean {
    return this.inline || false;
  }

  public get placeholderText(): string {
    return this.placeholder || this.title;
  }

  public get maxLength(): number {
    return this.control.maxLength;
  }

  public get readOnlyCss(): boolean {
    return this.control.readOnlyCss || false;
  }

  public getOptionText(option: any): string {
    if (!option) {
      return '';
    }

    let name = '';
    const activityAreaPart = option.activityArea ? `- ${option.activityArea}` : '';
    const zipCodePart = option.generalInfoZipCode ? `(${option.generalInfoZipCode}) ` : '';

    if (option.generalInfo?.isCompany === false) {
      name = option.generalInfo?.lastName ?? '';
    } else {
      name = option.generalInfo?.firstName ?? '';
    }

    return `${name} ${zipCodePart} ${activityAreaPart}`;
  }

  public get autocompleteLabelField(): string {
    return this.control.autocompleteLabelField ?? 'name';
  }

  public get autocompleteValueField(): string {
    return this.control.autocompleteValueField ?? 'id';
  }

  public get escapeFirstDigits(): boolean {
    return this.control.escapeFirstDigits;
  }

  public get hideSuccessMark(): boolean {
    return this.control.hideSuccessMark || false;
  }

  public displayWith(id: string | number): string {
    const option = this.autocompleteOptions.find(option => `${option?.[this.autocompleteValueField]}` === `${id}`);
    let name = '';

    if (!option) {
      return '';
    }

    if (option.generalInfo?.isCompany === false) {
      name = option.generalInfo?.lastName ?? '';
    } else {
      name = option[constants.firstNamePropertyName] ?? '';
    }

    return name;
  }

  private _filterOptions(value: string | number): any[] {
    const collection = this.autocompleteOptions?.filter(item => {
      if (item.generalInfo?.isCompany === false) {
        return item.generalInfo?.lastName?.toLowerCase().includes(`${value}`?.toLowerCase());
      } else {
        return item.generalInfo?.firstName?.toLowerCase().includes(`${value}`?.toLowerCase());
      }
    });

    return collection?.sort((a, b) => a[this.autocompleteLabelField]?.localeCompare(b[this.autocompleteLabelField]));
  }
}
