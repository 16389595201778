import { NumberFormControl } from 'projects/difference/app/shared/components/controls/number-form-control/number-form-control';
import { CheckboxFormControl } from '../../controls/checkbox-form-control/checkbox-form-control';
import { NameWithDigitsFormControl } from '../../controls/name-with-digits-form-control/name-with-digits-form-control';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DateFormControl } from '../../controls/date-form-control/date-form-control';
import { DropDownFormControl } from '../../controls/dropdown-form-control/dropdown-form-control';
import { CheckboxListFormControl } from '../../controls/checkbox-list-form-control/checkbox-list-form-control';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { IntroductionService } from './introduction.service';
import { CompanyInfo, DataFieldConfig, Introduction, Lookup } from 'projects/difference/webapi/Difference.WebApi';
import { AbstractForm, FormValuesChangedModel } from '../abstract-form';
import { getIntroductionFormComponentControl, introductionFormToViewModel } from './introduction-form.extensions';
import { formFields } from '../../../constants/form-fields';
import { LookupDataService, LookupTypeId } from 'projects/difference/app/services/lookup-data.service';
import { CustomerDataService } from 'projects/difference/app/services/customer-data.service';
import { constants } from '../../../constants/constants';
import { collections } from '../../../constants/collections';
import { SelectedCompanyDataService } from 'projects/difference/app/services/selected-company-data.service';

@Component({
  selector: 'app-besoin-form',
  templateUrl: './besoin-form.component.html'
})
export class BesoinFormComponent extends AbstractForm<Introduction> implements OnChanges {
  @Input() mode: FormMode;

  public form: FormGroup;
  public activityAreaOtherId = this.lookupDataService.lookupDataObj[LookupTypeId.ActivityAreas].otherId;
  public sourceInfoAboutUsOtherId = this.lookupDataService.lookupDataObj[LookupTypeId.SourceInfoAboutUs].otherId;

  public comptabiliteDateControlName = this.lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].comptabiliteDateControlName;
  public socialDateControlName = this.lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].socialDateControlName;
  public juridicalDateControlName = this.lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].juridicalDateControlName;
  public fiscaliteDateControlName = this.lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].fiscaliteDateControlName;
  public auditLegalDateControlName = this.lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].auditLegalDateControlName;
  public conseilOtherDateControlName = this.lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].conseilOtherDateControlName;

  public comptabiliteId = this.lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].comptabiliteId;
  public socialId = this.lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].socialId;
  public juridiqueId = this.lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].juridiqueId;
  public fiscaliteId = this.lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].fiscaliteId;
  public auditLegalId = this.lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].auditLegalId;
  public conseilOtherId = this.lookupDataService.lookupDataObj[LookupTypeId.MissionTypes].conseilOtherId;

  public nafCodeControl: NameWithDigitsFormControl;
  public activityStartDateControl: DateFormControl;
  public activityAreaControl: DropDownFormControl;
  public otherActivityAreaControl: NameWithDigitsFormControl;
  public missionTypeControl: CheckboxListFormControl;
  public otherMissionTypeControl: NameWithDigitsFormControl;
  public isCustomMissionsStartDateControl: CheckboxFormControl;
  public preferStartDateControl: DateFormControl;

  public comptabiliteDateControl: DateFormControl;
  public socialDateControl: DateFormControl;
  public juridicalDateControl: DateFormControl;
  public fiscaliteDateControl: DateFormControl;
  public auditLegalDateControl: DateFormControl;
  public conseilOtherDateControl: DateFormControl;

  public individualsPercentageControl: NumberFormControl;
  public professionalsPercentageControl: NumberFormControl;
  public communitiesPercentageControl: NumberFormControl;
  public othersPercentageControl: NumberFormControl;

  public activityStartDateTitle: string = '';
  public turnoverTitle: string = '';
  public percentagesSumError: boolean = false;
  public collections = collections;
  public summ: number = 0;

  constructor(
    public introductionService: IntroductionService,
    public lookupDataService: LookupDataService,
    public customerDataService: CustomerDataService,
    public selectedCompanyDataService: SelectedCompanyDataService
  ) {
    super();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const modeChanges = changes?.mode && changes?.mode.currentValue !== undefined && changes?.mode.currentValue !== changes?.mode.previousValue;
    const profileGuidChanges = changes?.profileGuid && changes?.profileGuid.currentValue !== undefined && changes?.profileGuid.currentValue !== changes?.profileGuid.previousValue;

    if (modeChanges || profileGuidChanges) {
      this.hasCompany = this.customerDataService.hasCompanyValue;
      await this.runFormRequiredOperations();
      this.initNewFormGroup();
      this.initControls();
      this.initSubscriptions();
      this.restoreAnswers();
      this.hasCompanyOrNotProcessing();
      super.ngOnChanges(changes);
    }
  }

  hasCompanyOrNotProcessing() {
    this.processControlsDependsOnHasCopmany();
    this.activityStartDateTitle = this.customerDataService.hasCompanyValue ? 'Date du début d’activité' : 'Date de début d’activité prévue (si connue)';
    this.turnoverTitle = this.customerDataService.hasCompanyValue
      ? 'Chiffre d’affaires (annuel, hors taxe, du dernier exercice clos) en k€ HT'
      : 'Chiffre d’affaires annuel prévisionnel (si connu) en k€ HT';
  }

  processControlsDependsOnHasCopmany(): void {
    if (this.hasCompany === false) {
      this.nafCodeControl.markAsNotRequired();

      this.individualsPercentageControl.markAsNotRequired();
      this.professionalsPercentageControl.markAsNotRequired();
      this.communitiesPercentageControl.markAsNotRequired();
      this.othersPercentageControl.markAsNotRequired();
    } else {
      this.nafCodeControl?.markRequiredOrNot(this.getIsControlRequired(formFields.introductionForm.nafCode));

      this.individualsPercentageControl?.markRequiredOrNot(this.getIsControlRequired(formFields.introductionForm.individualsPercentage));
      this.professionalsPercentageControl?.markRequiredOrNot(this.getIsControlRequired(formFields.introductionForm.professionalsPercentage));
      this.communitiesPercentageControl?.markRequiredOrNot(this.getIsControlRequired(formFields.introductionForm.communitiesPercentage));
      this.othersPercentageControl?.markRequiredOrNot(this.getIsControlRequired(formFields.introductionForm.othersPercentage));
    }
  }

  async runFormRequiredOperations(): Promise<void> {
    this.form = null;
    this.config = await this.introductionService.getConfig();
    this.formData = await this.introductionService.get(this.profileGuid);
    this.formId = this.formData?.introductionId;
  }

  initControls(): void {
    this.nafCodeControl = this.form.get(formFields.introductionForm.nafCode) as NameWithDigitsFormControl;
    this.activityStartDateControl = this.form.get(formFields.introductionForm.activityStartDate) as DateFormControl;
    this.activityAreaControl = this.form.get(formFields.introductionForm.activityAreaId) as DropDownFormControl;
    this.otherActivityAreaControl = this.form.get(formFields.introductionForm.activityAreaDescription) as NameWithDigitsFormControl;
    this.missionTypeControl = this.form.get(formFields.introductionForm.missions) as CheckboxListFormControl;
    this.otherMissionTypeControl = this.form.get(formFields.introductionForm.otherMissionRequestDescription) as NameWithDigitsFormControl;
    this.isCustomMissionsStartDateControl = this.form.get(formFields.introductionForm.isCustomMissionsStartDate) as CheckboxFormControl;

    this.individualsPercentageControl = this.form.get(formFields.introductionForm.individualsPercentage) as NumberFormControl;
    this.professionalsPercentageControl = this.form.get(formFields.introductionForm.professionalsPercentage) as NumberFormControl;
    this.communitiesPercentageControl = this.form.get(formFields.introductionForm.communitiesPercentage) as NumberFormControl;
    this.othersPercentageControl = this.form.get(formFields.introductionForm.othersPercentage) as NumberFormControl;

    this.preferStartDateControl = this.form.get(formFields.introductionForm.missionsStartDate) as DateFormControl;

    this.comptabiliteDateControl = this.form.get(this.comptabiliteDateControlName) as DateFormControl;
    this.socialDateControl = this.form.get(this.socialDateControlName) as DateFormControl;
    this.juridicalDateControl = this.form.get(this.juridicalDateControlName) as DateFormControl;
    this.fiscaliteDateControl = this.form.get(this.fiscaliteDateControlName) as DateFormControl;
    this.auditLegalDateControl = this.form.get(this.auditLegalDateControlName) as DateFormControl;
    this.conseilOtherDateControl = this.form.get(this.conseilOtherDateControlName) as DateFormControl;
  }

  initSubscriptions(): void {
    this.subscriptionHandler.subscriptions = this.selectedCompanyDataService.selectedCompanyExtraInformation$.subscribe((data: CompanyInfo) => {
      if (data.dateOfFirstActivity) {
        this.activityStartDateControl.patchValue(data.dateOfFirstActivity);
      }
      if (data.nafCode) {
        this.nafCodeControl.patchValue(data.nafCode);
      }
    });

    this.subscriptionHandler.subscriptions = this.activityAreaControl.valueChanges.subscribe((value: number) => {
      this.otherActivityAreaControl.markRequiredOrNot(value === this.activityAreaOtherId);
    });

    this.subscriptionHandler.subscriptions = this.missionTypeControl.valueChanges.subscribe((missionsIds: string[]) => {
      this.otherMissionTypeControl.markRequiredOrNot(missionsIds !== undefined && missionsIds?.indexOf(this.conseilOtherId.toString()) !== -1);
      this.customerDataService.setCustomizedMissionsIds(missionsIds);
      this.processOtherDates(missionsIds);
    });

    this.subscriptionHandler.subscriptions = this.isCustomMissionsStartDateControl.valueChanges.subscribe((value: boolean) => {
      this.comptabiliteDateControl?.markRequiredOrNot(value && this.isComptabiliteMission);
      if (!this.comptabiliteDateControl?.value) {
        this.comptabiliteDateControl?.patchValue(this.preferStartDate);
      }
      this.socialDateControl?.markRequiredOrNot(value && this.isSocialMission);
      if (!this.socialDateControl?.value) {
        this.socialDateControl?.patchValue(this.preferStartDate);
      }
      this.juridicalDateControl?.markRequiredOrNot(value && this.isJuridicalMission);
      if (!this.juridicalDateControl?.value) {
        this.juridicalDateControl?.patchValue(this.preferStartDate);
      }
      this.fiscaliteDateControl?.markRequiredOrNot(value && this.isFiscaliteMission);
      if (!this.fiscaliteDateControl?.value) {
        this.fiscaliteDateControl?.patchValue(this.preferStartDate);
      }
      this.auditLegalDateControl?.markRequiredOrNot(value && this.isAuditMission);
      if (!this.auditLegalDateControl?.value) {
        this.auditLegalDateControl?.patchValue(this.preferStartDate);
      }
      this.conseilOtherDateControl?.markRequiredOrNot(value && this.isConseilOtherMission);
      if (!this.conseilOtherDateControl?.value) {
        this.conseilOtherDateControl?.patchValue(this.preferStartDate);
      }
    });

    this.subscriptionHandler.subscriptions = this.customerDataService.getHasCompany().subscribe((hasCompanyValue: boolean) => {
      this.hasCompany = hasCompanyValue;
      this.hasCompanyOrNotProcessing();
    });

    this.subscriptionHandler.subscriptions = this.form.valueChanges.subscribe((value: any) => {
      if (this.hasCompany) {
        if (
          (this.individualsPercentageControl.value === undefined || this.individualsPercentageControl.value === null) &&
          (this.professionalsPercentageControl.value === undefined || this.professionalsPercentageControl.value === null) &&
          (this.communitiesPercentageControl.value === undefined || this.communitiesPercentageControl.value === null) &&
          (this.othersPercentageControl.value === undefined || this.othersPercentageControl.value === null)
        ) {
          this.percentagesSumError = false;
          return;
        }

        this.summ = 0;
        this.summ += this.individualsPercentageControl.value ?? 0;
        this.summ += this.professionalsPercentageControl.value ?? 0;
        this.summ += this.communitiesPercentageControl.value ?? 0;
        this.summ += this.othersPercentageControl.value ?? 0;

        if (this.summ !== 100) {
          const data = { model: this.form.getRawValue(), validState: false, isPristine: this.form.pristine, formId: this.formId, profileGuid: this.profileGuid } as FormValuesChangedModel;
          setTimeout(() => {
            this.onFormValuesChanged.emit(data);
          });
        } else {
          const data = { model: this.form.getRawValue(), validState: this.form.valid, isPristine: this.form.pristine, formId: this.formId, profileGuid: this.profileGuid } as FormValuesChangedModel;
          setTimeout(() => {
            this.onFormValuesChanged.emit(data);
          });
        }

        this.percentagesSumError = this.summ !== 100;
      }
    });
  }

  processOtherDates(missionsIds: string[]): void {
    if (this.isOtherDates) {
      if (this.isComptabiliteMission && !this.comptabiliteDateControl.value) {
        this.comptabiliteDateControl.markRequiredOrNot(missionsIds && this.isComptabiliteMission);
        this.comptabiliteDateControl.patchValue(this.preferStartDate);
      }
      if (this.isSocialMission && !this.socialDateControl.value) {
        this.socialDateControl.markRequiredOrNot(missionsIds && this.isSocialMission);
        this.socialDateControl.patchValue(this.preferStartDate);
      }
      if (this.isJuridicalMission && !this.juridicalDateControl.value) {
        this.juridicalDateControl.markRequiredOrNot(missionsIds && this.isJuridicalMission);
        this.juridicalDateControl.patchValue(this.preferStartDate);
      }
      if (this.isFiscaliteMission && !this.fiscaliteDateControl.value) {
        this.fiscaliteDateControl.markRequiredOrNot(missionsIds && this.isFiscaliteMission);
        this.fiscaliteDateControl.patchValue(this.preferStartDate);
      }
      if (this.isAuditMission && !this.auditLegalDateControl.value) {
        this.auditLegalDateControl.markRequiredOrNot(missionsIds && this.isAuditMission);
        this.auditLegalDateControl.patchValue(this.preferStartDate);
      }
      if (this.isConseilOtherMission && !this.conseilOtherDateControl.value) {
        this.conseilOtherDateControl.markRequiredOrNot(missionsIds && this.isConseilOtherMission);
        this.conseilOtherDateControl.patchValue(this.preferStartDate);
      }
    }
  }

  initNewFormGroup(): void {
    const isReadOnly = this.mode === FormMode.View;

    this.form = new FormGroup({
      Missions: new CheckboxListFormControl(false, isReadOnly, {
        collection: this.lookupDataService.lookupDataObj[LookupTypeId.MissionTypes],
        valuePropertyName: constants.namePropertyName,
        keyPropertyName: constants.keyPropertyName,
        withTooltip: true
      })
    });

    this.config.forEach((configItem: DataFieldConfig) => {
      const controlToBeAdded = getIntroductionFormComponentControl(configItem, this.mode, this.lookupDataService);

      if (configItem.name !== formFields.introductionForm.customizedMissions && controlToBeAdded) {
        this.form.addControl(configItem.name, controlToBeAdded);
      } else {
        const missionTypesCollection = this.lookupDataService.lookupDataObj[LookupTypeId.MissionTypes];

        missionTypesCollection.forEach((item: Lookup) => {
          const controlToBeAdded = getIntroductionFormComponentControl(configItem, this.mode, this.lookupDataService);
          const controlName = item.controlName;
          this.form.addControl(controlName, controlToBeAdded, { emitEvent: true });
        });
      }
    });
  }

  restoreAnswers(): void {
    if (this.isNew) {
      return null;
    } else {
      const viewModel = introductionFormToViewModel(this.lookupDataService, this.config, this.formData);
      this.form.patchValue(viewModel);
      this.isCustomMissionsStartDateControl.updateValueAndValidity();
      this.checkAndRestoreSelectedCompanyData();
    }

    this.onFormDataLoaded.next(true);
  }

  checkAndRestoreSelectedCompanyData(): void {
    if (this.selectedCompanyDataService.selectedCompany) {
      if (this.nafCodeControl.value === undefined || this.nafCodeControl.value === null) {
        this.nafCodeControl.patchValue(this.selectedCompanyDataService.selectedCompany.code_naf);
        this.form.markAsDirty();
      }
    }
    if (this.selectedCompanyDataService.selectedCompanyExtraInformation?.dateOfFirstActivity) {
      if (this.activityStartDateControl.value === undefined || this.activityStartDateControl.value === null) {
        this.activityStartDateControl.patchValue(this.selectedCompanyDataService.selectedCompanyExtraInformation.dateOfFirstActivity);
        this.form.markAsDirty();
      }
    }
  }

  get isNew(): boolean {
    return !this.formData;
  }

  get preferStartDate(): Date {
    return this.form.get(formFields.introductionForm.missionsStartDate).value;
  }

  get isActivityOther(): boolean {
    return this.activityAreaControl.value === this.activityAreaOtherId;
  }

  get isOtherDates(): boolean {
    return this.isCustomMissionsStartDateControl.value === true;
  }

  get isComptabiliteMission(): boolean {
    return this.missionTypeControl?.value && this.missionTypeControl?.value?.indexOf(this.comptabiliteId.toString()) !== -1;
  }

  get isSocialMission(): boolean {
    return this.missionTypeControl?.value && this.missionTypeControl?.value?.indexOf(this.socialId.toString()) !== -1;
  }

  get isJuridicalMission(): boolean {
    return this.missionTypeControl?.value && this.missionTypeControl?.value?.indexOf(this.juridiqueId.toString()) !== -1;
  }

  get isFiscaliteMission(): boolean {
    return this.missionTypeControl?.value && this.missionTypeControl?.value?.indexOf(this.fiscaliteId.toString()) !== -1;
  }

  get isAuditMission(): boolean {
    return this.missionTypeControl?.value && this.missionTypeControl?.value?.indexOf(this.auditLegalId.toString()) !== -1;
  }

  get isConseilOtherMission(): boolean {
    return this.missionTypeControl.value && this.missionTypeControl?.value?.indexOf(this.conseilOtherId.toString()) !== -1;
  }

  get isEditMode(): boolean {
    return this.mode === FormMode.Edit;
  }
}
