import { HttpClient } from '@angular/common/http';
import { SirenFormControl } from './../../../controls/siren-form-control/siren-form-control';
import { formFields } from './../../../../constants/form-fields';
import { CheckboxFormControl } from './../../../controls/checkbox-form-control/checkbox-form-control';
import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DropDownFormControl } from '../../../controls/dropdown-form-control/dropdown-form-control';
import { collections } from '../../../../constants/collections';
import { NameFormControl } from '../../../controls/name-form-control/name-form-control';
import { NameWithDigitsFormControl } from '../../../controls/name-with-digits-form-control/name-with-digits-form-control';
import { DataFieldConfig, GeneralInfo, Lookup } from 'projects/difference/webapi/Difference.WebApi';
import { GeneralInfoService } from './general-info.service';
import { contactMainFormToViewModel, getContactMainFormComponentControl } from './contact-main-form.extensions';
import { AbstractForm } from '../../abstract-form';
import { NameAutocompleteFormControl } from '../../../controls/name-autocomplete-form-control/name-autocomplete-form-control';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { LookupDataService, LookupTypeId } from 'projects/difference/app/services/lookup-data.service';
import { CustomerDataService } from 'projects/difference/app/services/customer-data.service';
import { OfficesApiService } from 'projects/difference-admin/app/services/offices-api.service';
import { SelectedCompanyDataService } from 'projects/difference/app/services/selected-company-data.service';
import { AdditionalInfoService } from '../../info/info-form/additional-info.service';
import { AutocompleteFormControl } from '@controls/autocomplete-form-control/autocomplete-form-control';
import { PappersService } from 'projects/difference-admin/app/services/pappers.service';

@Component({
  selector: 'app-contact-main-form',
  templateUrl: './contact-main-form.component.html'
})
export class ContactMainFormComponent extends AbstractForm<GeneralInfo> implements OnChanges {
  @Input() mode: FormMode;

  public isCompanyControl: CheckboxFormControl;
  public jurTypeControl: AutocompleteFormControl;
  public civilityControl: AutocompleteFormControl;
  public lastNameControl: NameFormControl;
  public sourceInfoAboutUsControl: DropDownFormControl;
  public sourceInfoAboutUsOtherControl: NameWithDigitsFormControl;
  public partnerIdControl: NameAutocompleteFormControl;
  public sourceInfoAboutUsOtherId = this.lookupDataService.lookupDataObj[LookupTypeId.SourceInfoAboutUs].otherId;
  public surPrescriptionAnswerId = this.lookupDataService.lookupDataObj[LookupTypeId.SourceInfoAboutUs].surPrescriptionId;
  public collections = collections;
  public sirenNumberControl: SirenFormControl;
  public officeControl: DropDownFormControl;
  public selectedCompany: any;
  public firstNameControl: NameFormControl;
  public selectedCompanyName: any;

  constructor(
    public generalInfoService: GeneralInfoService,
    public lookupDataService: LookupDataService,
    public customerDataService: CustomerDataService,
    public officesApiService: OfficesApiService,
    public selectedCompanyDataService: SelectedCompanyDataService,
    public additionalInfoService: AdditionalInfoService,
    public pappersService: PappersService,
    public cdr: ChangeDetectorRef
  ) {
    super();
  }

  async ngOnChanges(changes: any): Promise<void> {
    const modeChanges = changes?.mode && changes?.mode.currentValue !== undefined && changes?.mode.currentValue !== changes?.mode.previousValue;
    const profileGuidChanges = changes?.profileGuid && changes?.profileGuid.currentValue !== undefined && changes?.profileGuid.currentValue !== changes?.profileGuid.previousValue;

    if (modeChanges || profileGuidChanges) {
      this.hasCompany = this.customerDataService.hasCompanyValue;
      await this.runFormRequiredOperations();
      this.initNewFormGroup();
      this.initControls();
      this.initSubscriptions();
      this.restoreAnswers();
      this.hasCompanyOrNotProcessing();
      super.ngOnChanges(changes);
    }
  }

  async runFormRequiredOperations(): Promise<void> {
    this.form = null;
    this.config = await this.generalInfoService.getConfig();
    this.formData = await this.generalInfoService.get(this.profileGuid);
    this.formId = this.formData?.generalInfoId;
  }

  initNewFormGroup(): void {
    this.form = new FormGroup({});

    this.config.forEach((configItem: DataFieldConfig) => {
      const controlToBeAdded = getContactMainFormComponentControl(configItem, this.mode, this.lookupDataService);

      if (controlToBeAdded) {
        this.form.addControl(configItem.name, controlToBeAdded);
      }
    });
  }

  initControls(): void {
    this.isCompanyControl = this.form.get(formFields.contactMainForm.isCompany) as CheckboxFormControl;
    this.jurTypeControl = this.form.get(formFields.contactMainForm.jurTypeId) as AutocompleteFormControl;
    this.civilityControl = this.form.get(formFields.contactMainForm.civilityId) as AutocompleteFormControl;
    this.officeControl = this.form.get(formFields.contactMainForm.office) as DropDownFormControl;
    this.firstNameControl = this.form.get(formFields.contactMainForm.firstName) as NameFormControl;
    this.lastNameControl = this.form.get(formFields.contactMainForm.lastName) as NameFormControl;

    this.sourceInfoAboutUsControl = this.form.get(formFields.contactMainForm.sourceInfoAboutUs) as DropDownFormControl;
    this.sourceInfoAboutUsOtherControl = this.form.get(formFields.contactMainForm.sourceInfoAboutUsOther) as NameWithDigitsFormControl;
    this.partnerIdControl = this.form.get(formFields.contactMainForm.partnerId) as NameAutocompleteFormControl;

    this.sirenNumberControl = this.form.get(formFields.contactMainForm.sirenNumber) as SirenFormControl;
    this.processControlsDependsOnHasCopmany();
  }

  processIsCompanyValue(isCompany: boolean): void {
    if (isCompany === true) {
      this.firstNameControl.markRequiredOrNot(this.getIsControlRequired(formFields.contactMainForm.firstName));
      this.lastNameControl.markAsNotRequired();
    } else {
      this.firstNameControl.markRequiredOrNot(this.getIsControlRequired(formFields.contactMainForm.firstName));
      this.lastNameControl.markRequiredOrNot(this.getIsControlRequired(formFields.contactMainForm.firstName));
    }

    this.form.updateValueAndValidity();
  }

  initSubscriptions(): void {
    this.subscriptionHandler.subscriptions = this.sourceInfoAboutUsControl.valueChanges.subscribe((value: number) => {
      if (value === this.sourceInfoAboutUsOtherId) {
        this.sourceInfoAboutUsOtherControl.markRequiredOrNot(this.getIsControlRequired(formFields.contactMainForm.sourceInfoAboutUsOther));
      } else {
        this.sourceInfoAboutUsOtherControl.markAsNotRequired();
      }

      if (value === this.surPrescriptionAnswerId) {
        this.partnerIdControl.markRequiredOrNot(this.getIsControlRequired(formFields.contactMainForm.partnerId));
      } else {
        this.partnerIdControl.patchValue(null);
        this.partnerIdControl.markAsNotRequired();
      }
    });

    this.subscriptionHandler.subscriptions = this.customerDataService.getHasCompany().subscribe((hasCompanyValue: boolean) => {
      this.hasCompany = hasCompanyValue;
      this.hasCompanyOrNotProcessing();
    });
  }

  async restoreAnswers(): Promise<void> {
    if (this.isShowControl(formFields.contactMainForm.office, this.config)) {
      const offices = await this.officesApiService.getOffices().toPromise();
      this.officeControl.setCollection(offices);
    }

    if (this.isNew) {
      this.isCompanyControl.patchValue(true);
    } else {
      const viewModel = contactMainFormToViewModel(this.config, this.formData);
      this.form.patchValue(viewModel);

      if (this.isCompanyControl.value === true) {
        this.selectedCompanyName = this.firstNameControl.value;
        this.processIsCompanyValue(this.isCompanyControl.value);
      }
    }

    this.subscriptionHandler.subscriptions = this.isCompanyControl.valueChanges.subscribe((isCompanyValue: boolean) => {
      this.processIsCompanyValue(isCompanyValue);

      if (isCompanyValue === true) {
        this.jurTypeControl.patchValue(null);
        this.civilityControl.patchValue(null);
      } else {
        this.jurTypeControl.patchValue(this.lookupDataService.lookupDataObj[LookupTypeId.JurTypes].entrepreneurIndividuelId);
        this.firstNameControl.patchValue(null);
      }

      this.lastNameControl.patchValue(null);
    });

    this.onFormDataLoaded.next(true);
  }

  async onCompanySelected(company: any): Promise<void> {
    this.selectedCompany = company;
    this.selectedCompanyDataService.selectedCompany = company;

    this.form.get(formFields.contactMainForm.commercialName).patchValue(company.nom_entreprise);
    this.form.get(formFields.contactMainForm.sirenNumber).patchValue(company.siren);
    this.form.get(formFields.contactMainForm.addressLine).patchValue(company.siege.adresse_ligne_1);
    this.form.get(formFields.contactMainForm.zipCode).patchValue(company.siege.code_postal);
    this.form.get(formFields.contactMainForm.city).patchValue(company.siege.ville);

    const formeJuridiqueFromResponse = company.forme_juridique ?? '';

    if (this.isCompanyControl.value === true) {
      let collection: Lookup[] = this.lookupDataService.lookupDataObj[LookupTypeId.JurTypes];

      const formeJuridiqueFromResponseId = collection.find((item: Lookup) => {
        return item.name === formeJuridiqueFromResponse;
      })?.id;

      if (formeJuridiqueFromResponseId) {
        this.jurTypeControl.patchValue(formeJuridiqueFromResponseId);
      }
    }

    if (this.selectedCompanyDataService.selectedCompany?.siren) {
      this.selectedCompanyDataService.setSelectedCompanyExtraInformation(await this.additionalInfoService.getCompanyInfo(this.selectedCompanyDataService.selectedCompany?.siren));
    }
  }

  hasCompanyOrNotProcessing() {
    this.processControlsDependsOnHasCopmany();
  }

  processControlsDependsOnHasCopmany(): void {
    if (this.hasCompany === false) {
      this.sirenNumberControl.markAsNotRequired();
    } else {
      this.sirenNumberControl?.markRequiredOrNot(this.getIsControlRequired(formFields.contactMainForm.sirenNumber));
    }
  }

  get isSourceInfoAboutUsOther(): boolean {
    return this.sourceInfoAboutUsControl.value === this.sourceInfoAboutUsOtherId;
  }

  get isSourceInfoAboutUsSurPrescription(): boolean {
    return this.sourceInfoAboutUsControl.value === this.surPrescriptionAnswerId;
  }

  get isNew(): boolean {
    return !this.formData;
  }

  get isCompany(): boolean {
    return this.isCompanyControl.value;
  }

  get isEditMode(): boolean {
    return this.mode === FormMode.Edit;
  }
}
