import { DayMonthFormControl } from './../../../controls/day-month-form-control/day-month-form-control';
import { FormMode } from 'projects/difference-admin/app/shared/models/form-modes';
import { AdditionalInfo, DataFieldConfig } from 'projects/difference/webapi/Difference.WebApi';
import { collections } from '../../../../constants/collections';
import { constants } from '../../../../constants/constants';
import { formFields } from '../../../../constants/form-fields';
import { DateFormControl } from '../../../controls/date-form-control/date-form-control';
import { InfoFormControl } from '../../../controls/info-form-control/info-form-control';
import { NameFormControl } from '../../../controls/name-form-control/name-form-control';
import { NameWithDigitsFormControl } from '../../../controls/name-with-digits-form-control/name-with-digits-form-control';
import { RadioGroupFormControl } from '../../../controls/radio-group-form-control/radio-group-form-control';
import { FormValuesChangedModel } from '../../abstract-form';
import { generateUtcFrom } from 'projects/difference/app/shared/utils/date-time.utils';

function getAdditionalInfoFormComponentControl(config: DataFieldConfig, mode: FormMode): any {
  const isReadOnly = mode === FormMode.View ? true : config.isReadOnly;

  switch (config.name) {
    case formFields.additionalInfo.isAccountantEverHired: {
      return new RadioGroupFormControl(config.isRequired, isReadOnly, {
        collection: collections.yesNoBoolean,
        valuePropertyName: constants.valuePropertyName,
        keyPropertyName: constants.namePropertyName
      });
    }
    case formFields.additionalInfo.isCommitmentEndDateKnown: {
      return new RadioGroupFormControl(config.isRequired, isReadOnly, {
        collection: collections.yesNoBoolean,
        valuePropertyName: constants.valuePropertyName,
        keyPropertyName: constants.namePropertyName
      });
    }
    case formFields.additionalInfo.commitmentEndDate: {
      return new DateFormControl(config.isRequired, isReadOnly);
    }
    case formFields.additionalInfo.currentAccountantCompanyName: {
      return new NameWithDigitsFormControl(config.isRequired, isReadOnly);
    }
    case formFields.additionalInfo.currentAccountantName: {
      return new NameFormControl(config.isRequired, isReadOnly);
    }
    case formFields.additionalInfo.corporateAccountsClosingDate: {
      return new DayMonthFormControl(config.isRequired, isReadOnly);
    }
    case formFields.additionalInfo.currentOrganizationDescription: {
      return new InfoFormControl(config.isRequired, isReadOnly, true);
    }
  }
}

function additionalInfoFormToViewModel(config: DataFieldConfig[], data: any): any {
  const viewModel: any = {};

  if (!data) {
    return viewModel;
  }

  config?.forEach((configItem: any) => {
    const propertyName = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);
    viewModel[configItem.name] = data[propertyName];
  });

  return viewModel;
}

function additionalInfoViewModelToDataModel(config: DataFieldConfig[], data: FormValuesChangedModel): AdditionalInfo {
  const objModel: any = {};
  const model = data?.model;

  if (data) {
    config?.forEach((configItem: any) => {
      const propertyNameForSaveObj = configItem.propertyName[0].toLowerCase() + configItem.propertyName.slice(1);

      if (configItem.name === formFields.additionalInfo.commitmentEndDate) {
        objModel[propertyNameForSaveObj] = model[configItem.name] ? generateUtcFrom(new Date(model[configItem.name])) : null;
      } else {
        objModel[propertyNameForSaveObj] = model[configItem.name];
      }
    });

    objModel.profileGuid = data.profileGuid;
    objModel.additionalInfoId = data.formId;
  }

  return new AdditionalInfo(objModel);
}

function getDayAndMonthFromString(date: string): string {
  if (!date || date === '') {
    return '';
  }

  const day = date.split(' ')[0];
  const month: string = date.split(' ')[1] + '';
  const monthValue = collections.months.find((item: any) => {
    return item.key === month;
  })?.value;

  return `${day}/${monthValue}`;
}

export { getAdditionalInfoFormComponentControl, additionalInfoFormToViewModel, additionalInfoViewModelToDataModel, getDayAndMonthFromString };
