import { FormControl, Validators, ValidatorFn, AbstractControlOptions } from '@angular/forms';

export abstract class AbstractFormControl extends FormControl {
  constructor(private required: boolean = false, disabled: boolean = false, controlOptions?: AbstractControlOptions | null) {
    super({ value: '', disabled }, controlOptions);

    const validators = this.getValidators();
    if (required) {
      validators?.push(Validators.required);
    }
    this.setValidators(validators);
  }

  public get isRequired(): boolean {
    return this.required;
  }

  public get isDisabled(): boolean {
    return this.disabled;
  }

  public markAsRequiredAndEnable(): void {
    this.markAsRequired();
    this.enable();
  }

  public markAsValid(): void {
    this.setErrors(null);
  }

  public markAsInvalid(): void {
    this.setErrors([{ incorrect: true }]);
  }

  public markAsNotRequiredAndDisable(): void {
    this.markAsNotRequired();
    this.disable();
  }

  markRequiredOrNot(isRequired: boolean) {
    if (isRequired) {
      this.markAsRequired();
    } else {
      this.markAsNotRequired();
    }
  }

  public markAsRequired(): void {
    let isAlreadyRequired = false;
    this.required = true;

    const validators = this.getValidators();

    validators?.forEach((validatorFn: ValidatorFn) => {
      if (validatorFn.name === 'required') {
        isAlreadyRequired = true;
      }
    });

    if (!isAlreadyRequired) {
      validators.push(Validators.required);
    }

    this.setValidators(validators);

    this.updateValueAndValidity();
  }

  public markAsNotRequired(): void {
    this.required = false;
    let validators = this.getValidators();

    validators = validators.filter((validatorFn: ValidatorFn) => {
      return validatorFn.name !== 'required';
    });

    this.setValidators(validators);

    this.updateValueAndValidity();
  }

  public removeAllValidators(): void {
    this.setValidators([]);
    this.updateValueAndValidity();
  }

  protected abstract getValidators(): ValidatorFn[];
}
