export const constants = {
    orgId: {
        diff: { value: 'diff' },
        admin: { value: 'admin' }
    },
    steps: {
        contacts: {
            name: 'contacts',
            countOfTheSteps: 10
        },
        besoin: {
            name: 'besoin',
            countOfTheSteps: 25
        },
        information: {
            name: 'information',
            countOfTheSteps: 40
        },
        volumes: {
            name: 'volumes',
            countOfTheSteps: 55
        },
        regime: {
            name: 'regime',
            countOfTheSteps: 70
        },
        comptabilite: {
            name: 'comptabilite',
            countOfTheSteps: 85
        },
        social: {
            name: 'social',
            countOfTheSteps: 90
        },
        files: {
            name: 'files',
            countOfTheSteps: 95
        },
        confirmation: {
            name: 'confirmation',
            countOfTheSteps: 100
        }
    },
    dateFormat: 'DD/MM/YYYY',
    validStatus: 'VALID',
    invalidStatus: 'INVALID',
    valuePropertyName: 'description',
    titlePropertyName: 'title',
    namePropertyName: 'name',
    fullNamePropertyName: 'fullName',
    firstNamePropertyName: 'firstName',
    profileGuidPropertyName: 'profileGuid',
    companySearchConditionTitle: 'Entreprise',
    jurNameSearchConditionTitle: 'Nom juridique',
    keyPropertyName: 'id',
    tooltipHideDelay: 300,
    defaultDataTablePageLength: 10,
    fileFilterModes: {
        bilan: 1,
        previsionnel: 2,
        piecesIdentite: 3,
        declarations: 4,
        autres: 5
    },
    maxLength: {
        ville: 32,
        address: 100,
        complementAdresse: 38,
        locality: 38
    },
    fileUploaderMaxFileSize: 5242880, // File size in bytes
    fileUploaderSupportedTypes: [
        'image/jpeg',
        'image/tif',
        'image/png',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ], // http://www.iana.org/assignments/media-types/media-types.xhtml
    onlySaveExtArray: [
        'xls',
        'xlsx',
        'doc',
        'docx'
    ],
    imageExtArray: [
        'jpeg',
        'jpg',
        'png',
        'tif'
    ],
    maxLengthInfoText: 55,
    urlKeysForNonBlockingSpinner: [
        'generate'
    ]
};
